import React from "react"
import { graphql } from "gatsby"
import PageLayout from "../components/page-layout"
import SEO from "../components/seo"
import styled from "styled-components"

const UnorderedList = styled.ul`
  margin-top: -10px;
`

export default function About({ data }) {
  return (
    <PageLayout>
      <SEO title="About Daily Defi"/>
      <h1>About {data.site.siteMetadata.title}</h1>
      <p>
        dailydefi.org serves to provide useful resources for the space of decentralized finance.
      </p>
      <p>
        Not financial advice, the site is only for educational content.
      </p>

      <p>
        dailydefi.org attempts to make the best attempt at providing correct and up to date
        information, but this information is presented without warranty and with no guarantees.
      </p>

      <p>
        For decentralized finance contracting services, see: <a href="https://ellipticdefisolutions.com">
          https://ellipticdefisolutions.com
        </a>
      </p>

      <p>
        To send an email to the owner of this site, please 
        contact &nbsp;
        <code>dailydefiorg@gmail.com</code>
      </p>

      <p>Donation Addresses</p>
      <UnorderedList>
        <li>ETH: 0x37172fA0316a06c6d5e2Bb00785e0f356D27CFF8</li>
        <li>xDai: 0x37172fA0316a06c6d5e2Bb00785e0f356D27CFF8</li>
        <li>BSC: 0x37172fA0316a06c6d5e2Bb00785e0f356D27CFF8</li>
      </UnorderedList>
    </PageLayout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
